import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { TooltipComponent } from '@enginuity/core/molecules/tooltip/tooltip.component';
import { ModalService } from '@services/core-services/modal.service';
import { MediaGalleryDialogComponent } from '@enginuity/media/organisms/media-gallery-dialog/media-gallery-dialog.component';
import { ProtectionService } from '@services/core-services/protection.service';
import { split } from '@angular-devkit/core';
import { filter } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [CommonModule, CeIconComponent, NgOptimizedImage, TooltipComponent, RouterModule],
})
export class SideNavComponent {
  active_link = '';
  store_slug: string = '';
  constructor(
    public route: ActivatedRoute,
    private readonly router: Router,
    private readonly modal: ModalService,
    private readonly protectionService: ProtectionService
  ) {}

  @Input()
  limited: 'yes' | 'no' = 'yes';

  // get settingsLink() {
  //   const id = this.protectionService.getCurrentStoreSnapshot()?.id;
  //   return `/settings/store/general`;
  // }

  ngOnInit() {
    this.store_slug = this.route.snapshot.paramMap.get('store_slug') || 'default-slug';
    this.updateActiveRoute(this.router.url);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveRoute(event.url);
      }
    });
  }

  updateActiveRoute(url: string) {
    this.active_link = url.split('/').filter(segment => segment)[2] || '';
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const keyCode = event.key;

    if (keyCode === 'ArrowUp') {
      this.navigateUp();
    } else if (keyCode === 'ArrowDown') {
      this.navigateDown();
    }
  }

  navigateUp() {
    const menuItems = document.querySelectorAll('.menu-item');
    const currentIndex = Array.from(menuItems).findIndex(item => item.classList.contains('active'));

    if (currentIndex > 0) {
      const previousMenuItem = menuItems[currentIndex - 1] as HTMLElement;
      previousMenuItem.focus();
    }
  }

  navigateDown() {
    const menuItems = document.querySelectorAll('.menu-item');
    const currentIndex = Array.from(menuItems).findIndex(item => item.classList.contains('active'));

    if (currentIndex < menuItems.length - 1) {
      const nextMenuItem = menuItems[currentIndex + 1] as HTMLElement;
      nextMenuItem.focus();
    }
  }

  isActive(active_link: string, urls: string[]): boolean {
    return !!urls.find(url => active_link.toLowerCase().startsWith(url.toLowerCase()));
  }

  openGallery() {
    this.modal.open(MediaGalleryDialogComponent, null, {
      modalDialogClass: 'modal-gallery-dialog',
      windowClass: 'modal-gallery-window',
    });
  }
}
